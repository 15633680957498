import { CabButton, CabTextInput } from "@CabComponents";
import { Alert, Box, Divider, FormControl, FormLabel, Link, styled, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import StepHeader from "../../components/Onboarding/StepHeader";
import colors from "../../colors";
import { emailRegex, PAGE_URL, PRIVACY_POLICY, TERMS_OF_SERVICE } from "../../constants";
import { GOOGLE_LOGO } from "../../resourceUrls";
import CabSpinner from "@CabComponents/CabSpinner";
import { RESET_PASSWORD_MESSAGE } from "../../utils/cognitoErrorUtils";
import { isLargeFormatDevice } from "../../utils/screenSizeUtils";
import { AmplifyAuthProvider } from "../../utils/authUtils";
import { PublicOrganization } from "../../store/cabinetApi/generated/core";

export interface LoginProps {
  onSubmit: (email: string, password: string) => Promise<void>;
  email?: string;
  submissionErrors: string[];
  orgCode?: string;
  organization?: PublicOrganization;
  orgExists: boolean;
  loading: boolean;
  authUrl?: string;
  federatedLogin: (
    value: { customProvider?: string, provider?: AmplifyAuthProvider}
  ) => void
  onLoginWithGoogle: () => Promise<void>;
}

export interface FormInput {
  email: string;
  password: string;
}

const LoginStep = ({ 
  onSubmit, email, submissionErrors, orgCode, organization, orgExists, loading, federatedLogin, authUrl,
  onLoginWithGoogle
}: LoginProps): ReactElement => {
  const [loggingInWithGoogle, setLoggingInWithGoogle] = useState(false);

  const {
    control, formState: { errors, isSubmitting }, handleSubmit: handleFormSubmit, setValue,
  } = useForm<FormInput>({
    defaultValues: { email: '', password: '' },
  });

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email, setValue]);

  useEffect(() => {
    setLoggingInWithGoogle(loading);
  }, [loading]);

  const handleSubmit = async (values: FormInput) => {
    await onSubmit(values.email, values.password);
  };

  const handleLoginWithGoogle = async () => {
    setLoggingInWithGoogle(true);
    try {
      await onLoginWithGoogle();
    } catch (err) {
      setLoggingInWithGoogle(false);
    }
  };

  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%" padding={1}>
      <StepHeader
        title="Log in"
        text={orgCode && orgExists && organization ? `Sign in with your ${organization.name || ''} account` : ""}
      />

      {!orgCode ? (
        <form onSubmit={handleFormSubmit(handleSubmit)} style={{ width: 'inherit' }}>
          <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
            <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%"
              maxWidth="400px" flex={1} alignItems="center" paddingLeft={1} paddingRight={1}
            >
              <FormControl sx={{ flex: 1, width: '100%' }}>
                <FormLabel>Work email</FormLabel>
                <Controller name="email" control={control} rules={{ required: true, pattern: emailRegex }} 
                  render={({ field: { ref, ...field } }) => (
                    <CabTextInput {...field} autoComplete="email" />
                  )}
                />
                {errors.email && (
                  <Alert severity="error" sx={{ marginTop: 1 }}>
                    {errors?.email?.type === 'required' ? 'Email is required'
                      : errors.email?.type === 'pattern' ? 'Please enter a valid email address'
                        : null}
                  </Alert>
                )}
              </FormControl>

              <FormControl sx={{ flex: 1, width: '100%' }}>
                <FormLabel>Password</FormLabel>
                <Controller name="password" control={control} rules={{ required: true }} 
                  render={({ field: { ref, ...field } }) => (
                    <CabTextInput {...field} type="password" autoComplete="current-password"/>
                  )}
                />
                {errors.password && (
                  <Alert severity="error" sx={{ marginTop: 1 }}>
                    {errors?.password?.type === 'required' ? 'Password is required' : null}
                  </Alert>
                )}
              </FormControl>

              <CabButton
                size="large"
                type="submit"
                sx={{ width: '100%' }}
                disabled={isSubmitting || loading || loggingInWithGoogle}
              >
                {isSubmitting || loading || loggingInWithGoogle ? <CabSpinner scale={1} /> : 'Log In'}
              </CabButton>

              {submissionErrors.map(err => (
                <Alert key={err} severity="error" sx={{ marginTop: 1, width: '100%' }}>
                  {err === RESET_PASSWORD_MESSAGE ? (
                    <span>
                      {err}
                      <Link component={RouterLink} to={PAGE_URL.RESET_PASS} variant="body2" color={colors.black800}>
                        Click here to reset it
                      </Link>
                    </span>
                  ) : (
                    err
                  )}
                </Alert>
              ))}


              <Box display="flex" gap={1}>
                <Typography variant="body2" color={colors.black600}>Forgot your password?</Typography>
                <Link component={RouterLink} to={PAGE_URL.RESET_PASS} variant="body2" color={colors.black600}>
                  Reset it
                </Link>
              </Box>

              <Divider sx={{ width: "100%" }}>
                <Typography variant="body2" color={colors.black600}>Or</Typography>
              </Divider>

              <CabButton
                size="large"
                buttonType="tertiary"
                color="accent"
                sx={{ width: '100%', color: colors.black800, borderColor: colors.black300, 
                  backgroundColor: colors.white900, fontWeight: 700 }}
                disabled={isSubmitting || loading || loggingInWithGoogle}
                icon={<img src={GOOGLE_LOGO} height={24} width={24} alt="Google" style={{ marginRight: 6 }} />}
                onClick={handleLoginWithGoogle}
              >
                Sign in with Google
              </CabButton>
              <CabButton
                size="large"
                buttonType="tertiary"
                color="accent"
                type="button"
                sx={{
                  width: '100%', color: colors.black800, borderColor: colors.black300, fontWeight: 700,
                  backgroundColor: colors.white900,
                }}
                disabled={isSubmitting || loading || loggingInWithGoogle}
                onClick={() => navigate(
                  PAGE_URL.SSO_SEARCH + `?redirect=${PAGE_URL.LOGIN}`,
                  {
                    state: {
                      from: { pathname: authUrl }
                    }
                  }
                )}
              >
                Sign in with SSO
              </CabButton>

              {isLargeFormatDevice() &&
                <Box display="flex" gap={1}>
                  <Typography variant="body2" color={colors.black600}>Don't have an account?</Typography>
                  <Link component={RouterLink} to={PAGE_URL.SIGNUP} variant="body2" color={colors.black600}>
                    Create one
                  </Link>
                </Box>
              }
            </Box>
          </Box>

        </form>
      ) : (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} maxWidth={'350px'}
          marginTop={2}>
          {orgExists ? (
            <>
              {organization?.logo && (
                <CompanyLogo src={organization.logo} alt={organization?.name ?? ""} />
              )}
              <CabButton
                size="large"
                buttonType="tertiary"
                color="accent"
                type="button"
                sx={{ width: '100%', color: colors.black800, borderColor: colors.black300, 
                  backgroundColor: colors.white900, fontWeight: 700 }}
                disabled={isSubmitting || loading || loggingInWithGoogle}
                onClick={() => federatedLogin({ customProvider: organization?.cognito_provider_name || '' })}
              >
                Sign in to Cabinet
              </CabButton>
              <Box display="flex" marginTop={2}>
                <Typography variant="body2" color={colors.black600}>
                  Want to log in with a different method?&nbsp;
                </Typography>
                <Link component={RouterLink} to={PAGE_URL.LOGIN} variant="body2" color={colors.black600}>Log in</Link>
              </Box>
              <Box display="flex" marginTop={2}>
                <Typography textAlign={'center'} variant="body2" color={colors.black600}>
                  By signing in to Cabinet, you agree to our&nbsp;
                  <Link href={PRIVACY_POLICY} color={colors.black600} target={'_blank'}>
                    Privacy Policy
                  </Link>
                  &nbsp;and&nbsp;
                  <Link href={TERMS_OF_SERVICE} color={colors.black600} target={'_blank'}>
                    Terms of Service
                  </Link>
                </Typography>
              </Box>
            </>
          ) : (
            <Typography marginTop={2} variant="body1"> 
              This organization is not yet on Cabinet. Please contact your organization's administrator.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

const CompanyLogo = styled('img', { label: "CompanyLogo" })(
  {
    maxWidth: 250,
    maxHeight: 74,
    marginBottom: 24,
  }
);

export default LoginStep;
